:root {
  --title-color: white;
  --font-color: white;
  --font-second-color: #c3beb6;
  --font-third-color: #a49c90;
  --first-color: #181a1b;
  --second-color: #2d2d2d;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  color: var(--font-color);
  font-family: sans-serif;
}

body {
  margin: 0;
  background-color: var(--first-color);
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: var(--second-color);
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: var(--first-color);
}
